import {
  FloatingFocusManager,
  FloatingPortal,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import clsx from 'clsx';
import { FormEvent, ReactElement, ReactNode, useState } from 'react';
import ButtonField from 'src/components/common/ButtonField';
import './Dialog.scss';

export type Props = {
  className?: string;
  title: string;
  description?: ReactElement | string | string[];
  isOpen?: boolean;
  body?:
    | ReactNode
    | (({
        warningWasClicked,
        acceptWasClicked,
      }: {
        warningWasClicked: boolean;
        acceptWasClicked: boolean;
      }) => ReactNode);
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;

  cancelText?: string;
  onCancelClick: () => void;

  warningText?: string;
  warningDisabled?: boolean;
  onWarningClick?: () => Promise<void> | void;

  acceptText?: string;
  acceptDisabled?: boolean;
  onAcceptClick?: () => Promise<void> | void;
};

function Dialog({
  className,
  title,
  description,
  isOpen,
  body: bodyProps,
  size = 'small',
  isDisabled,

  cancelText = 'Cancel',
  onCancelClick,

  warningText = 'Warning',
  warningDisabled: warningDisabledProps,
  onWarningClick,

  acceptText = 'Ok',
  acceptDisabled: acceptDisabledProps,
  onAcceptClick,
}: Props) {
  const warningDisabled = isDisabled || warningDisabledProps;
  const acceptDisabled = isDisabled || acceptDisabledProps;
  const cancelDisabled = isDisabled;

  const { context, refs } = useFloating();
  const { getFloatingProps } = useInteractions();
  const [warningWasClicked, setWarningWasClicked] = useState(false);
  const [acceptWasClicked, setAcceptWasClicked] = useState(false);

  const handleCancelClick = () => {
    onCancelClick();
  };

  const body =
    bodyProps instanceof Function
      ? bodyProps({ warningWasClicked, acceptWasClicked })
      : bodyProps;

  const handleWarningClick = (event?: FormEvent) => {
    event?.preventDefault();
    setWarningWasClicked(true);

    if (warningDisabled || !onWarningClick) return;

    onWarningClick();
  };

  const handleAcceptClick = (event: FormEvent) => {
    event.preventDefault();
    setAcceptWasClicked(true);

    if (acceptDisabled || !onAcceptClick) return;

    onAcceptClick();
  };

  return (
    <FloatingPortal>
      {isOpen && (
        <>
          <button
            className="dialog-box-underlay"
            aria-label="Click to dismiss dialog"
            onClick={handleCancelClick}
            type="button"
          />

          {/* initialFocus needs to be -1 otherwise it tries to focus other buttons */}
          <FloatingFocusManager context={context} initialFocus={-1}>
            <div
              role={onWarningClick && !onAcceptClick ? 'alertdialog' : 'dialog'}
              {...getFloatingProps({
                ref: refs.floating,
                className: clsx([
                  'dialog-box',
                  `dialog-box--size-${size}`,
                  className,
                ]),
              })}
            >
              <form
                name={title}
                className="dialog-box__form"
                onSubmit={
                  onAcceptClick ? handleAcceptClick : handleWarningClick
                }
              >
                <h4 className="dialog-box__title">{title}</h4>

                {!description ? null : (
                  <div className="dialog-box__description">
                    {Array.isArray(description) ? (
                      description.map((paragraph, paragraphIndex) => (
                        <p
                          key={paragraphIndex}
                          className="dialog-box__description-item"
                        >
                          {paragraph}
                        </p>
                      ))
                    ) : typeof description === 'string' ? (
                      <p className="dialog-box__description-item">
                        {description}
                      </p>
                    ) : (
                      description
                    )}
                  </div>
                )}

                {body && <div className="dialog-box__body">{body}</div>}

                <div className="dialog-box__actions">
                  <div className="dialog-box__button-list">
                    <ButtonField
                      autoFocus
                      className="dialog-box__button"
                      onClick={handleCancelClick}
                      label={cancelText}
                      size="medium"
                      disabled={cancelDisabled}
                    />

                    {!!(onAcceptClick && onWarningClick) && (
                      <span className="dialog-box__button-gap" />
                    )}

                    {onWarningClick && (
                      <ButtonField
                        allowInteractionWhenDisabled
                        className="dialog-box__button"
                        disabled={warningDisabled}
                        color="destructive"
                        label={warningText}
                        size="medium"
                        {...(onAcceptClick
                          ? {
                              onClick: handleWarningClick,
                            }
                          : {
                              type: 'submit',
                            })}
                      />
                    )}

                    {onAcceptClick && (
                      <ButtonField
                        allowInteractionWhenDisabled
                        className="dialog-box__button"
                        type="submit"
                        disabled={acceptDisabled}
                        color="call-to-action"
                        label={acceptText}
                        size="medium"
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </FloatingFocusManager>
        </>
      )}
    </FloatingPortal>
  );
}

export default Dialog;
