import { SENTRY_DSN } from '@motional-cc/fe/keys';
import { Security } from '@okta/okta-react';
import { BrowserTracing } from '@sentry/browser';
import { ErrorBoundary, init as initSentry } from '@sentry/react';
import noop from 'lodash/noop';
import { Fragment, useEffect } from 'react';
import { env } from 'src/api/hosts';
import { oktaAuth } from 'src/auth/okta';
import useDialog from 'src/components/Dialog/dialog-hook';
import {
  VITE_COMMIT_HASH,
  VITE_DEBUG_ERROR_REPORTING,
  VITE_SENTRY_DSN,
} from 'src/config/env';
import { ThemeProvider } from 'src/contexts/theme-context';
import Root from './Root';
import './App.scss';

const sentryDsn = VITE_SENTRY_DSN || SENTRY_DSN;

const ENVS_WITH_ERROR_REPORTING = ['prd', 'hil', 'pre-prd', 'stg'] as const;

const ENABLE_ERROR_REPORTING = !!(
  sentryDsn &&
  (VITE_DEBUG_ERROR_REPORTING ||
    // this defaults localhost using stg data to not send sentry errors
    (!globalThis.location.origin.includes('//localhost:') &&
      env &&
      (ENVS_WITH_ERROR_REPORTING as Readonly<string[]>).includes(env)))
);

if (ENABLE_ERROR_REPORTING) {
  initSentry({
    dsn: sentryDsn,
    release: `command-center@${VITE_COMMIT_HASH}`,
    environment: env,
    autoSessionTracking: true,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'motional.cc/', /^\//],
      }),
    ],
  });
}

function CoreApp() {
  useEffect(function throwIfNoEnvIsSet() {
    if (
      (!env || env === 'PLEASE_SET') &&
      globalThis.location.origin.includes('//localhost:')
    ) {
      throw new Error('Environment must be set while developing.');
    }
  }, []);

  return (
    // we're handling restoring original URI ourselves
    <Security oktaAuth={oktaAuth} restoreOriginalUri={noop}>
      <ThemeProvider>
        <Root />
      </ThemeProvider>
    </Security>
  );
}

function App() {
  const { DialogComponent } = useDialog();
  return (
    <ErrorBoundary
      fallback={(errorData) => (
        <DialogComponent
          isOpen
          cancelText="Refresh the page"
          title={(
            (errorData.error.message || errorData.error.cause?.['name']) ??
            'An error occurred'
          ).slice(0, 150)}
          description={
            <>
              {ENABLE_ERROR_REPORTING && (
                <p className="dialog-box__description">
                  If the issue persists, please contact support citing the Error
                  ID: {errorData.eventId}
                </p>
              )}

              <details>
                <summary className="dialog-box__description">
                  See more details
                </summary>

                <div className="the-app__error-boundary-description">
                  {errorData.error.message && (
                    <p className="dialog-box__description">
                      {errorData.error.message.split('')[0]}
                    </p>
                  )}

                  <p className="dialog-box__description">Stack trace</p>
                  <p className="dialog-box__description">
                    {errorData.componentStack
                      ?.split('\n')
                      .filter((paragraph) => paragraph.trimEnd())
                      .map((paragraph, paragraphIndex) => (
                        <Fragment key={paragraphIndex}>
                          {paragraph}
                          <br />
                        </Fragment>
                      ))}
                  </p>
                </div>
              </details>
            </>
          }
          onCancelClick={() => {
            document.location.reload();
          }}
        />
      )}
    >
      <CoreApp />
    </ErrorBoundary>
  );
}

export default App;
